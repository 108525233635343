module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RehanToday","short_name":"RehanToday","start_url":"/","background_color":"#20232b","theme_color":"#9cfffc","display":"standalone","icon":"src/images/favicon.ico","icons":[{"src":"/src/images/favicons/favicon-57.jpg","sizes":"57x57","type":"image/jpg"},{"src":"/src/images/favicons/favicon-76.jpg","sizes":"76x76","type":"image/jpg"},{"src":"/src/images/favicons/favicon-96.jpg","sizes":"96x96","type":"image/jpg"},{"src":"/src/images/favicons/favicon-120.jpg","sizes":"120x120","type":"image/jpg"},{"src":"/src/images/favicons/favicon-128.jpg","sizes":"128x128","type":"image/jpg"},{"src":"/src/images/favicons/favicon-144.jpg","sizes":"144x144","type":"image/jpg"},{"src":"/src/images/favicons/favicon-152.jpg","sizes":"152x152","type":"image/jpg"},{"src":"/src/images/favicons/favicon-180.jpg","sizes":"180x180","type":"image/jpg"},{"src":"/src/images/favicons/favicon-192.jpg","sizes":"192x192","type":"image/jpg"},{"src":"/src/images/favicons/favicon-196.jpg","sizes":"196x196","type":"image/jpg"},{"src":"/src/images/favicons/favicon-226.jpg","sizes":"226x226","type":"image/jpg"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c39f4cbde9dbe1f1ab02054a2294a90e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
